import React, { useContext, useState, useEffect, ChangeEvent } from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Skeleton from 'react-loading-skeleton';
import CheckoutPriceSummary from '../../components/CheckoutPriceSummary';
import LoadingSpinner from '../../components/LoadingSpinner';
import CartItem from '../../components/CartItem';
import APIError from '../../components/APIError';
import GoogleReCAPTCHATos from '../../components/GoogleReCAPTCHATos';
import PaymentIframe from '../../components/PaymentIframe';
import PayPal from '../../components/PaymentProcessors/PayPal';
import CartContext from '../../context/CartContext';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import CustomerInformationContext from '../../context/CustomerInformationContext';
import OrderInformationContext from '../../context/OrderInformationContext';
import {
  PaymentMethodContext,
  PaymentMethodsType,
} from '../../context/PaymentProviderContext';
import StorePreviewContext from '../../context/StorePreviewContext';
import CheckoutShippingForm from './CheckoutShippingForm';
import CheckoutBillingForm from './CheckoutBillingForm';
import { GATransactionEvent } from '../../utilities/analytics';
import FormatUSD from '../../utilities/Formatter';
import uuid from '../../utilities/uuid';
import VisaImg from '../../images/Visa-light.png';
import MastercardImg from '../../images/MasterCard-light.png';
import DiscoverImg from '../../images/Discover-light.png';
import AmExImg from '../../images/AmericanExpress-light.png';
import PayPalLogo from '../../images/PayPal_Logo_Horizontal_Full_Color_RGB.png';
import postWebCheckoutEstimate from '../../api/ShoppingApi/clients/WebCheckoutEstimate';
import WebCheckoutEstimateResponse from '../../api/responses/WebCheckoutEstimateResponse';
import WebCheckoutCaptureResponsePayPalTypes from '../../api/responses/WebCheckoutCaptureResponsePayPalTypes';
import WebCheckoutResponse from '../../api/responses/WebCheckoutResponse';
import postWebCheckout from '../../api/ShoppingApi/clients/WebCheckout';
import CustomerFormInformation, {
  CustomerShippingForm,
  CustomerBillingForm,
} from '../../types/CustomerInformation';
import DeliveryMethod from '../../types/DeliveryMethod';
import Styles from './CheckoutInfo.module.scss';

const CheckoutInfo = (): JSX.Element => {
  const theme = useTheme();
  const styles = makeStyles({
    list: {
      border: `1px solid ${theme.palette.divider}`,
    },
    listItem: {
      display: 'block',
    },
  })();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const retailerInformation = useContext(RetailerInformationContext);
  const customerInformation = useContext(CustomerInformationContext);
  const orderInformation = useContext(OrderInformationContext);
  const cart = useContext(CartContext);
  const { paymentMethod, setPaymentMethod } = useContext(PaymentMethodContext);
  const storePreview = useContext(StorePreviewContext);

  const [customerFormInformation, setCustomerFormInformation] = useState<
    CustomerFormInformation | undefined
  >(customerInformation?.customerInfo);
  const [compact, setCompact] = useState({
    customerInfo: false,
    shippingMethod: false,
    customerBill: false,
  });
  const [checkoutCost, setCheckoutCost] =
    useState<WebCheckoutEstimateResponse>();
  const [billingSameAsShipping, setBillingSameAsShipping] =
    useState<boolean>(true);
  const [shippingMethod, setShippingMethod] = useState<DeliveryMethod>(
    DeliveryMethod.Shipping
  );
  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<
    { error: { messages: string[] } } | undefined
  >();
  const [checkoutInfo, setCheckoutInfo] = useState<string>();
  const [paymentAVSError, setPaymentAVSError] = useState<string[]>();

  const tenantId = retailerInformation?.store.tenant.id;
  const storeId = retailerInformation?.store.id;
  const merchantId = retailerInformation?.store.merchantId;
  const qualifiesForFreeShipping =
    retailerInformation?.store.minimumOrderTotalToEarnFreeShipping &&
    cart?.subtotal &&
    cart.subtotal >=
      retailerInformation.store.minimumOrderTotalToEarnFreeShipping;
  const lessFreeShipping =
    (retailerInformation?.store.minimumOrderTotalToEarnFreeShipping || 0) -
    (checkoutCost?.subtotal || cart?.subtotal || 0);
  const hasFreeShippingRule = retailerInformation?.store.hasFreeShippingRule;

  const shippingRate = (value: number | undefined): string => {
    if (value && value > 0) {
      return FormatUSD.format(value);
    }
    if (hasFreeShippingRule && qualifiesForFreeShipping) {
      return 'Free';
    }
    return '';
  };
  const handlePaymentTypeChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setPaymentMethod(event.target.value as PaymentMethodsType);
  };

  useEffect(() => {
    if (
      cart?.items &&
      tenantId &&
      storeId &&
      customerInformation?.customerInfo?.billingAddress &&
      customerInformation?.customerInfo?.shippingAddress
    ) {
      if (
        checkoutInfo !==
          JSON.stringify({
            cart: cart.items,
            customerInfo: customerInformation.customerInfo,
          }) ||
        paymentError
      ) {
        setCheckoutInfo(
          JSON.stringify({
            cart: cart.items,
            customerInfo: customerInformation.customerInfo,
          })
        );
        setPaymentProcessing(true);
        const shippingAddress =
          shippingMethod === DeliveryMethod.Shipping
            ? customerInformation.customerInfo.shippingAddress
            : null;

        const billingUSA =
          shippingMethod === 'Pickup'
            ? {
                ...customerInformation.customerInfo.billingAddress,
                firstName: 'Customer',
                lastName: 'Pickup',
              }
            : customerInformation.customerInfo.billingAddress;

        const body = {
          billingAddress: billingUSA,
          shippingAddress,
          deliveryMethod: shippingMethod,
          customerEmailAddress:
            customerInformation.customerInfo.customerEmailAddress,
          customerPhoneNumber:
            customerInformation.customerInfo.customerPhoneNumber,
        };

        const fetchData = async (): Promise<void> => {
          setCheckoutCost(undefined);
          if (!executeRecaptcha) {
            Sentry.captureException('Recaptcha has not been loaded');
            return;
          }

          await executeRecaptcha('prepareCheckout')
            .then((challengeToken) => {
              postWebCheckoutEstimate(cart.sessionId, tenantId, storeId, {
                ...body,
                challengeToken,
              })
                .then((res) => {
                  setPaymentProcessing(false);
                  setCheckoutCost(res);
                })
                .catch((error) => {
                  setPaymentProcessing(false);
                  Sentry.captureException(error);
                });
            })
            .catch(() => {
              setCompact({ ...compact, customerInfo: false });
              setPaymentError({
                error: {
                  messages: ['Error with shipping info. Please try again.'],
                },
              });
              Sentry.captureException(
                'challengeToken: prepareCheckout - reCAPTCHA failed'
              );
            });
        };

        fetchData();
      }
    }
  }, [
    customerInformation,
    cart,
    compact,
    tenantId,
    storeId,
    paymentError,
    shippingMethod,
    checkoutInfo,
    executeRecaptcha,
    paymentMethod,
    retailerInformation?.store.pickupPolicy.pickupAddress,
  ]);

  useEffect(() => {
    GATransactionEvent('begin_checkout', undefined, {
      cartItems: cart?.items || [],
      subtotal: checkoutCost?.subtotal || cart?.subtotal || 0,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShippingMethod = (event: ChangeEvent<HTMLInputElement>): void => {
    const isPickup = event.target.value === 'Pickup';
    setShippingMethod(event.target.value as DeliveryMethod);
    setCompact({ ...compact, shippingMethod: isPickup });
    setBillingSameAsShipping(!isPickup);
  };

  const onShippingFormSubmit = (
    customerFormInfoSubmit: CustomerShippingForm
  ): void => {
    const { customerEmailAddress, customerPhoneNumber, ...addressInfo } =
      customerFormInfoSubmit;
    const { customerInfo } = customerInformation || {};
    const billingAddress =
      customerInfo?.billingAddress && !billingSameAsShipping
        ? { ...customerInfo.billingAddress }
        : { ...addressInfo };

    setPaymentAVSError(undefined);
    setPaymentError(undefined);
    setCustomerFormInformation({
      customerEmailAddress,
      customerPhoneNumber,
      deliveryMethod: shippingMethod,
      shippingAddress: addressInfo,
      billingAddress,
    });
    setCompact({ ...compact, customerInfo: true });

    if (customerInformation) {
      customerInformation.updateCustomerInfo({
        ...customerInformation.customerInfo,
        customerEmailAddress,
        customerPhoneNumber,
        deliveryMethod: shippingMethod,
        shippingAddress: addressInfo,
        billingAddress,
      });
    }

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const onShippingMethodSubmit = (): void =>
    setCompact({ ...compact, shippingMethod: true });
  const onBillingSameAsShipping = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    if (
      customerFormInformation &&
      customerInformation &&
      customerFormInformation.shippingAddress
    ) {
      const { shippingAddress } = customerFormInformation;
      const newCustomerFormInformation = {
        ...customerFormInformation,
        shippingAddress,
        billingAddress: shippingAddress,
      };

      if (
        JSON.stringify(customerFormInformation) !==
        JSON.stringify(newCustomerFormInformation)
      ) {
        setPaymentError(undefined);
        setCustomerFormInformation(newCustomerFormInformation);
        customerInformation.updateCustomerInfo(newCustomerFormInformation);
      }
    }

    setBillingSameAsShipping(event.target.value === 'true');
  };

  const onBillingFormSubmit = (
    customerBillingInfo: CustomerBillingForm
  ): void => {
    if (customerInformation && customerFormInformation) {
      const { customerEmailAddress, customerPhoneNumber } =
        customerInformation.customerInfo || {};

      const newCustomerFormInformation = {
        customerEmailAddress: customerEmailAddress || '',
        customerPhoneNumber,
        deliveryMethod: shippingMethod,
        shippingAddress: customerFormInformation.shippingAddress,
        billingAddress: customerBillingInfo,
      };

      setPaymentAVSError(undefined);
      setPaymentError(undefined);
      setCustomerFormInformation(newCustomerFormInformation);
      setCompact({ ...compact, customerBill: true });
      customerInformation.updateCustomerInfo(newCustomerFormInformation);
    }
  };

  const onPaymentSubmit = async (
    paymentToken: string,
    paypalresponse?: WebCheckoutResponse & WebCheckoutCaptureResponsePayPalTypes
  ): Promise<void> => {
    setPaymentProcessing(true);
    // The time-zone offset is the difference, in minutes, from local time to
    // UTC. Note that this means that the offset is positive if the local
    // timezone is behind UTC, and negative if it is ahead.
    const utcOffsetInMinutes = -new Date().getTimezoneOffset();

    if (
      tenantId &&
      storeId &&
      customerInformation?.customerInfo?.billingAddress &&
      customerInformation?.customerInfo?.shippingAddress &&
      cart &&
      orderInformation
    ) {
      const { shippingAddress, billingAddress, ...restCustomerInfo } =
        customerInformation.customerInfo;
      const shippingAddressMethod =
        shippingMethod === 'Shipping' ? { ...shippingAddress } : null;
      const body = {
        ...restCustomerInfo,
        billingAddress,
        shippingAddress: shippingAddressMethod,
        deliveryMethod: shippingMethod,
        paymentToken,
        sessionId: cart.sessionId,
        utcOffsetInMinutes,
        paymentAttemptId: uuid(),
      };

      if (paymentMethod === 'Card') {
        await postWebCheckout(tenantId, storeId, body)
          .then((response) => {
            if (response.isSuccess) {
              GATransactionEvent('purchase', undefined, {
                cartItems: cart.items,
                subtotal: cart.subtotal,
              });
              setPaymentError(undefined);
              orderInformation.updateCheckoutInfo({
                ...response,
                deliveryMethod: shippingMethod,
              });
              navigate('/checkout/confirmation');
            } else {
              setPaymentProcessing(false);

              const { addressError, zipCodeError } = response.failures;
              const billingErrors = [];
              if (addressError) billingErrors.push('address');
              if (zipCodeError) billingErrors.push('zipcode');
              if (billingErrors.length > 0) {
                if (billingSameAsShipping) {
                  setCompact({ ...compact, customerInfo: false });
                } else {
                  setCompact({ ...compact, customerBill: false });
                }
                setPaymentAVSError(billingErrors);
              }

              const allErrorMessages: string[] = Object.values(
                response.failures
              ).filter((ele) => ele) as string[];

              Sentry.withScope((scope) => {
                scope.setLevel('warning');
                Sentry.captureMessage(
                  `Warning: Payment Submit - User Information Issue. ${JSON.stringify(
                    allErrorMessages
                  )}`
                );
              });

              setPaymentError({
                error: {
                  messages: allErrorMessages.length
                    ? allErrorMessages
                    : ['Error during checkout:'],
                },
              });
            }
            setPaymentProcessing(false);
          })
          .catch((error) => {
            setPaymentProcessing(false);
            Sentry.captureException(error);
            setPaymentError({
              error: {
                messages: ['There was a problem. Please try again.'],
              },
            });
          });
      }

      if (paymentMethod === 'PayPal') {
        GATransactionEvent('purchase', undefined, {
          cartItems: cart.items,
          subtotal: cart.subtotal,
        });
        setPaymentError(undefined);
        if (paypalresponse) {
          orderInformation.updateCheckoutInfo({
            ...paypalresponse,
            deliveryMethod: shippingMethod,
          });
        }
        navigate('/checkout/confirmation');
      }
    }
  };

  const onPaymentFailure = (
    code: string,
    message = 'There was a problem processing your transaction.'
  ): void => {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      Sentry.captureMessage(`Warning - ${code}: Payment Failure - ${message}`);
    });

    switch (code) {
      case 'RS_PROPAY_AVS_ERROR':
        setPaymentError({
          error: {
            messages: [
              message ||
                'There was a problem verifying your address information.',
            ],
          },
        });

        break;
      case 'RS_PROPAY_ERROR':
        setPaymentError({
          error: {
            messages: [
              message ||
                'There was a problem verifying your payment. Please check your card information.',
            ],
          },
        });

        break;
      default:
        setPaymentError({
          error: {
            messages: [message],
          },
        });

        break;
    }
    setPaymentProcessing(false);
  };

  let cartItems: JSX.Element = <></>;

  if (!cart || cart.cartIsLoading) {
    cartItems = <LoadingSpinner />;
  } else if (cart instanceof Error) {
    cartItems = <APIError />;
  } else {
    if (!cart?.items?.length) {
      return <Navigate to="/shop" replace />;
    }

    cartItems = (
      <div className={Styles.summaryComplete}>
        <ul className={Styles.checkout_cart_list}>
          {cart.items.map((cartItem) => (
            <CartItem
              key={`${cartItem.productId}${cartItem.claimCode || ''}`}
              claimCode={cartItem.claimCode}
              streamId={cartItem.streamId}
              // discount={cartItem.discount}
              item={cartItem}
              productGroupId={cartItem.productGroupId}
              quantity={cartItem.quantity}
              readOnly
            />
          ))}
        </ul>
        <CheckoutPriceSummary
          subtotal={checkoutCost?.subtotal || cart?.subtotal || 0}
          shippingMethod={shippingMethod}
          shipping={checkoutCost?.shippingAmount}
          taxes={checkoutCost?.estimatedTaxAmount}
          shippingTax={checkoutCost?.estimatedShippingTaxAmount}
          total={checkoutCost?.estimatedTotal || cart?.subtotal || 0}
          topBorder
          taxStrategy={retailerInformation?.store.taxStrategy}
          qualifiesForFreeShipping={
            !!qualifiesForFreeShipping && hasFreeShippingRule
          }
        />
      </div>
    );
  }

  const customerContact = customerInformation?.customerInfo;
  const customerShipping = customerInformation?.customerInfo?.shippingAddress;
  const customerBilling = customerInformation?.customerInfo?.billingAddress;
  const paymentProcessors = retailerInformation?.store.paymentProcessors || [];
  // let paymentProcessors = retailerInformation?.store.paymentProcessors || [];
  // paymentProcessors = [...paymentProcessors, 'PayPal'];

  return (
    <div className="col-12">
      <div className="row justify-content-between">
        <div className="col-12 col-md-8 col-lg-6">
          <h3 className="mb-5">Secure Checkout</h3>

          {compact.customerInfo && (
            <List className={styles.list} disablePadding>
              {customerShipping && customerContact && (
                <>
                  <ListItem className={styles.listItem}>
                    <div className="row align-middle">
                      <div className="col-3 col-md-2 align-self-center">
                        Contact
                      </div>
                      <div className="col-9 col-md-7 align-self-center">
                        {customerContact.customerEmailAddress}
                        <br />
                        {customerContact.customerPhoneNumber}
                      </div>
                      <div className="col-12 col-md-3 align-self-center text-end">
                        <Button
                          color="primary"
                          onClick={() =>
                            setCompact({ ...compact, customerInfo: false })
                          }
                        >
                          Change
                        </Button>
                      </div>
                    </div>
                  </ListItem>
                  <Divider />
                  <ListItem className={styles.listItem}>
                    {shippingMethod === 'Shipping' ? (
                      <div className="row">
                        <div className="col-3 col-md-2 align-self-center">
                          Ship To
                        </div>
                        <div className="col-9 col-md-7 align-self-center">
                          {`${customerShipping?.firstName} ${customerShipping?.lastName}`}
                          <br />
                          {customerShipping?.line1}
                          {customerShipping?.line2 &&
                            ` ${customerShipping.line2}`}
                          <br />
                          {customerShipping?.city} {customerShipping?.state},{' '}
                          {customerShipping?.zip}, {customerShipping?.country}
                        </div>
                        <div className="col-12 col-md-3 align-self-center text-end">
                          <Button
                            color="primary"
                            onClick={() =>
                              setCompact({
                                ...compact,
                                customerInfo: false,
                              })
                            }
                          >
                            Change
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-3 col-md-2 align-self-center">
                          Pick up Address
                        </div>
                        <div className="col-9 col-md-7 align-self-center">
                          {
                            retailerInformation?.store.pickupPolicy
                              .pickupAddress.line1
                          }
                          {retailerInformation?.store.pickupPolicy.pickupAddress
                            .line2 &&
                            ` ${retailerInformation?.store.pickupPolicy.pickupAddress.line2}`}
                          <br />
                          {
                            retailerInformation?.store.pickupPolicy
                              .pickupAddress.city
                          }{' '}
                          {
                            retailerInformation?.store.pickupPolicy
                              .pickupAddress.state
                          }
                          ,{' '}
                          {
                            retailerInformation?.store.pickupPolicy
                              .pickupAddress.zip
                          }
                          ,{' '}
                          {
                            retailerInformation?.store.pickupPolicy
                              .pickupAddress.country
                          }
                        </div>
                        <div className="col-12 col-md-3 align-self-center text-end">
                          <Button
                            color="primary"
                            onClick={() =>
                              setCompact({
                                ...compact,
                                customerInfo: false,
                              })
                            }
                          >
                            Change
                          </Button>
                        </div>
                      </div>
                    )}
                  </ListItem>
                </>
              )}
              {compact.shippingMethod && shippingMethod === 'Shipping' && (
                <>
                  <Divider />
                  <ListItem className={styles.listItem}>
                    <div className={clsx(Styles.shippingCompact, 'row')}>
                      <div className="col-3 col-md-2 align-self-center">
                        Shipping
                      </div>
                      <div className="col-9 col-md-7 align-self-center">
                        {checkoutCost?.shippingAmount !== undefined ? (
                          shippingRate(checkoutCost.shippingAmount)
                        ) : (
                          <Skeleton width={'75px'} />
                        )}
                      </div>
                    </div>
                  </ListItem>
                </>
              )}
              {compact.customerBill && customerBilling && (
                <>
                  <Divider />
                  <ListItem className={styles.listItem}>
                    <div className="row">
                      <div className="col-3 col-md-2 align-self-center">
                        Billing Address
                      </div>
                      <div className="col-9 col-md-7 align-self-center">
                        {`${customerBilling?.firstName} ${customerBilling?.lastName}`}
                        <br />
                        {customerBilling?.line1}
                        {customerBilling?.line2 && ` ${customerBilling.line2}`}
                        <br />
                        {customerBilling?.city} {customerBilling?.state},{' '}
                        {customerBilling?.zip}, {customerBilling?.country}
                      </div>
                      <div className="col-12 col-md-3 align-self-center text-end">
                        <Button
                          color="primary"
                          onClick={() =>
                            setCompact({ ...compact, customerBill: false })
                          }
                        >
                          Change
                        </Button>
                      </div>
                    </div>
                  </ListItem>
                </>
              )}
            </List>
          )}
          {retailerInformation && !compact.customerInfo && (
            <CheckoutShippingForm
              customerInfo={customerFormInformation}
              onSubmit={onShippingFormSubmit}
              handleShippingMethod={handleShippingMethod}
              shippingMethod={shippingMethod}
              allowInStorePickup={
                retailerInformation.store.pickupPolicy.allowInStorePickup
              }
              returnPolicy={retailerInformation.store.returnPolicy.policy}
              shippingPolicy={retailerInformation.store.shippingPolicy.policy}
              privacyPolicy={
                retailerInformation.store.tenant.staticDocuments.PrivacyPolicy
              }
              termsOfUse={
                retailerInformation.store.tenant.staticDocuments.TermsOfUse
              }
              AVSerrors={billingSameAsShipping && paymentAVSError}
            />
          )}
          {!compact.shippingMethod && compact.customerInfo && (
            <div className="row">
              <div className="col-12">
                <h5 className="my-3">Shipping</h5>
                <p className="row">
                  <span className="col-auto me-auto">
                    Shipping
                    {checkoutCost &&
                      typeof checkoutCost.cartWeightInOunces === 'number' &&
                      ` (${(checkoutCost.cartWeightInOunces / 16).toFixed(
                        2
                      )} lbs)`}
                  </span>
                  <span className="col-auto">
                    {paymentProcessing ? (
                      <Skeleton width={'75px'} />
                    ) : (
                      shippingRate(checkoutCost?.shippingAmount)
                    )}
                  </span>
                </p>
                {hasFreeShippingRule && lessFreeShipping > 0 && (
                  <p>
                    <i>
                      <strong>Hint:</strong> If you add{' '}
                      {FormatUSD.format(lessFreeShipping)} to your cart you can
                      get <strong>FREE Shipping</strong>
                    </i>
                  </p>
                )}
                <div className="text-end my-4">
                  {hasFreeShippingRule && lessFreeShipping > 0 && (
                    <Button
                      component={Link}
                      to="/shop"
                      color="primary"
                      variant="outlined"
                      className="me-3"
                    >
                      Continue Shopping
                    </Button>
                  )}
                  <Button
                    data-testid="submit-shipping-method"
                    color="primary"
                    variant="contained"
                    onClick={onShippingMethodSubmit}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          )}
          {compact.customerInfo &&
            compact.shippingMethod &&
            !compact.customerBill && (
              <>
                {paymentProcessors.length > 1 && (
                  <>
                    <h5 className="my-3">Pay with</h5>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={paymentMethod}
                        className={Styles.paymentRadio}
                        onChange={handlePaymentTypeChange}
                      >
                        {paymentProcessors.includes('ProPay') && (
                          <FormControlLabel
                            value="Card"
                            control={<Radio />}
                            label={
                              <div>
                                <img
                                  src={VisaImg}
                                  className={Styles.cards_image}
                                  alt="Visa"
                                />
                                <img
                                  src={MastercardImg}
                                  className={Styles.cards_image}
                                  alt="Mastercard"
                                />
                                <img
                                  src={DiscoverImg}
                                  className={Styles.cards_image}
                                  alt="Discover"
                                />
                                <img
                                  src={AmExImg}
                                  className={Styles.cards_image}
                                  alt="American Express"
                                />
                              </div>
                            }
                          />
                        )}
                        {paymentProcessors.includes('PayPal') && (
                          <FormControlLabel
                            value="PayPal"
                            control={<Radio />}
                            label={
                              <div>
                                <img
                                  src={PayPalLogo}
                                  className={Styles.paypalLogo}
                                  alt="PayPal"
                                />
                              </div>
                            }
                          />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </>
                )}
                {paymentMethod === 'Card' && (
                  <>
                    <h5 className="my-3">Billing Address</h5>
                    {shippingMethod === 'Shipping' && (
                      <FormControl>
                        <RadioGroup
                          defaultValue="shippingAddress"
                          name="radio-buttons-group"
                          onChange={onBillingSameAsShipping}
                          value={billingSameAsShipping.toString()}
                          className="pb-3"
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Billing address is same as shipping"
                          />
                          {billingSameAsShipping && (
                            <div className="ps-5 pb-3 fw-bold">
                              {`${customerShipping?.firstName} ${customerShipping?.lastName}`}
                              <br />
                              {customerShipping?.line1}
                              {customerShipping?.line2 &&
                                ` ${customerShipping.line2}`}
                              <br />
                              {customerShipping?.city} {customerShipping?.state}
                              , {customerShipping?.zip},{' '}
                              {customerShipping?.country}
                            </div>
                          )}
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Enter a different billing address"
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                    {!billingSameAsShipping && (
                      <CheckoutBillingForm
                        onSubmit={onBillingFormSubmit}
                        sameAsShipping={billingSameAsShipping}
                        AVSerrors={!billingSameAsShipping && paymentAVSError}
                      />
                    )}
                  </>
                )}
              </>
            )}
          <div className="d-md-none">
            <h3 className="mb-5">Summary</h3>
            {cartItems}
          </div>
          <div>
            {retailerInformation &&
              !paymentAVSError &&
              compact.customerInfo &&
              compact.shippingMethod &&
              checkoutCost?.estimatedTotal && (
                <>
                  <h5 className="my-3">Payment Information</h5>
                  {paymentMethod === 'PayPal' && merchantId && (
                    <PayPal
                      onPaymentSubmit={onPaymentSubmit}
                      cost={checkoutCost.estimatedTotal}
                      merchantId={merchantId}
                    />
                  )}
                  {(billingSameAsShipping || compact.customerBill) &&
                    paymentMethod === 'Card' && (
                      <PaymentIframe
                        onTokenReceived={onPaymentSubmit}
                        onPaymentFailure={onPaymentFailure}
                        payFormUrl={checkoutCost.paymentFormUrl}
                        paymentError={paymentError?.error.messages}
                        paymentProcessing={paymentProcessing}
                        storePreview={storePreview}
                      />
                    )}
                </>
              )}
            <GoogleReCAPTCHATos />
          </div>
        </div>
        <div className="col-12 col-md-4 d-none d-md-block">
          <h3 className="mb-5">Summary</h3>
          {cartItems}
        </div>
      </div>
    </div>
  );
};

export default CheckoutInfo;
