import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';

export type PaymentMethodsType = 'Card' | 'PayPal';
type PaymentMethodsContextType = {
  paymentMethod: PaymentMethodsType;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethodsType>>;
};
type PropType = {
  children: ReactNode;
};

export const PaymentMethodContext = createContext<PaymentMethodsContextType>({
  paymentMethod: 'Card',
  setPaymentMethod: () => undefined,
});

const PaymentMethodProvider = ({ children }: PropType): JSX.Element => {
  const [paymentMethod, setPaymentMethod] =
    useState<PaymentMethodsType>('Card');

  return (
    <PaymentMethodContext.Provider value={{ paymentMethod, setPaymentMethod }}>
      {children}
    </PaymentMethodContext.Provider>
  );
};

export default PaymentMethodProvider;
